import type { LoaderFunctionArgs } from 'react-router';
import { data } from 'react-router';
import { Outlet, useLoaderData } from 'react-router';
import { getMe } from '@/api_clients/trainers/user_client';
import { Loading } from '@/components/loading';
import { SideBarContents } from '@/components/trainers/side_bar';
import { authenticate } from '@/utils/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const { idToken } = await authenticate(request);
  const ownersUrl = process.env.OWNERS_URL ?? `https://owners.equtum.dev`;

  const me = await getMe(idToken);
  return data({ me, ownersUrl });
}

export default function TrainersMain() {
  const data = useLoaderData<typeof loader>();
  if (!data) {
    return <Loading />;
  }
  const { me, ownersUrl } = data;
  return (
    <div className="flex h-full">
      <div className="print:hidden">
        <SideBarContents
          me={me.user}
          ownersUrl={ownersUrl}
          contracts={me.contracts}
          features={me.featureFlags}
        />
      </div>
      <div
        className="bg-surface-base h-svh flex-1 overflow-y-auto pt-[50px] lg:pt-0"
        style={{ scrollbarGutter: 'stable' }}
      >
        <Outlet />
      </div>
    </div>
  );
}
